import jewell from "../../static/0K3A7290.png"
import group from "../../static/group.png"
import group2 from "../../static/group2.png"
import group3 from "../../static/group3.png"
import handoffs from "../../static/handoffs.png"
import jaronn from "../../static/jaronn.png"
import sign2 from "../../static/sign2.png"
import stationary from "../../static/stationary.png"
import handoff from "../../static/handoff.png"
import blur from "../../static/blur.png"
import btwn from "../../static/btwn.png"
import circle from "../../static/circle.png"
import isso from "../../static/isso.png"
import fly from "../../static/fly.png"



import './HomePage'

function Carousel() {
    return (
        <div id="carouselItems" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
                <li data-target="#carouselItems" data-slide-to="0" className="active"></li>
                <li data-target="#carouselItems" data-slide-to="1"></li>
                <li data-target="#carouselItems" data-slide-to="2"></li>
                <li data-target="#carouselItems" data-slide-to="3"></li>
                <li data-target="#carouselItems" data-slide-to="4"></li>
                <li data-target="#carouselItems" data-slide-to="5"></li>
                <li data-target="#carouselItems" data-slide-to="6"></li>
                <li data-target="#carouselItems" data-slide-to="7"></li>
                <li data-target="#carouselItems" data-slide-to="8"></li>
                <li data-target="#carouselItems" data-slide-to="9"></li>
                <li data-target="#carouselItems" data-slide-to="10"></li>
                <li data-target="#carouselItems" data-slide-to="11"></li>
                <li data-target="#carouselItems" data-slide-to="12"></li>
                <li data-target="#carouselItems" data-slide-to="13"></li>

            </ol>
            <div className="carousel-inner">
            <div className="carousel-item active">
                  <img src={group} className="d-block w-100" alt="606 Academy 2022 Spring Training at Sports Center of Round Lake Park District"/>
                  <div className='carousel-caption d-none d-md-block'>
                    <p>At 606 Academy, basketball is the vehicle to influence and inspire those around us. Players build life-long, supportive friendships that help them -- and others -- persevere through adversity. 606 Academy provides high-quality basketball instruction, enrichment, camps, clinics and tournament opportunities because everyone deserves a chance to grow.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={jewell} className="d-block w-100" alt="Jewell Loyd poses with camper at 606 Academy Spring Training"/>
                  <div className='carousel-caption d-none d-md-block'>
                    <p>Special guest Jewell Loyd stops by our spring training at Round Lake Sports Center to inspire the kids.</p>
                  </div>
                </div>
                <div className="carousel-item">
                    <img src={handoffs} className="d-block w-100" alt="Child in Chicago Bulls jersey shoots a basket during 606 Academy 2022 Spring Training"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Hard Work & Dedication! Spring Training 2022 players warming up.</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={jaronn} className="d-block w-100" alt="Coach Jaronn teaching during 606 Academy Spring Training"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Coach Jaronn teaching during 606 Academy Spring Training.
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={group2} className="d-block w-100" alt="Last day of 2022 Spring Training celebration"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Last day of 2022 Spring Training celebration!
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={sign2} className="d-block w-100" alt="Hard Work and Dedication is the motto!"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Hard Work and Dedication is the motto!
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={group3} className="d-block w-100" alt="Tribute to Team USA and Jarryd & Jewell Loyd"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Tribute to Team USA and Jarryd & Jewell Loyd
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={stationary} className="d-block w-100" alt="Stationary ball handling at 2022 Spring Training"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Stationary ball handling at 2022 Spring Training
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={blur} className="d-block w-100" alt="Hands up on defense!"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Hands up on defense!
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={btwn} className="d-block w-100" alt="Hard work during warm-up drills"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Hard work during warm-up drills
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={isso} className="d-block w-100" alt="Huddle up!"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Huddle up!
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={circle} className="d-block w-100" alt="Bring it in and break it down!"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Bring it in and break it down!
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={fly} className="d-block w-100" alt="This kid is ready to fly!"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>This kid is ready to fly!
                      </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={handoff} className="d-block w-100" alt="Purpose"/>
                    <div className='carousel-caption d-none d-md-block'>
                      <p>Purpose
                      </p>
                    </div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselItems" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselItems" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
};

export default Carousel;