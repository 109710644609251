const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-april-8-to-april-29-tues-wed-thurs/",
    name: "3 Day Bundle T/W/TH",
    date: "",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-april-8-to-april-29-tues-wed-thurs/",
    name: "2 Day Bundle T/W/TH",
    date: "",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-april-8-to-april-29-tues-wed-thurs/",
    name: "April 8th",
    date: "April 29th",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-april-8-to-april-29-tues-wed-thurs/",
    name: "April 9th",
    date: "April 16th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-april-8-to-april-29-tues-wed-thurs/",
    name: "April 10th",
    date: "April 24th",
  },
]


const trainingInfo = [
  "No Training April 23rd, 30th, and May 1st",
  "Registration closes one week prior to start date",
  "Individual day registration starts at $45 and available upon request",
  "Bring a basketball and a water bottle.",
  "1st Grade to 11th Grade",
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}