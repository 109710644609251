import {trainingDrills, trainingInfo, tuesdayTrainingOption, thursdayTrainingOption, trainingOption1Bundle, trainingOption2Bundle} from './PopInTrainingData'
import PurchaseList from '../../components/PurchaseList';
import PurchaseListNavTraining from '../../components/PurchaseListNavTraining';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function Training() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training Jan 7th to 16th W/Coach Greg</h4>
        <h4 className='disclaimer'>3rd to 6th Grade is 6:00PM to 7:30PM/7 Grade to HS 7:30PM to 9PM</h4>
        <div className='camp__container'>
          <div className="purchase">
          <PurchaseListNavTraining url="PopInTraining" />
          <PurchaseList 
            type="ThreeDayBundle"
            header="3rd to 6th Grade"
            subhead="6PM to 7:30PM"
            data={trainingOption1Bundle}
          />
          <PurchaseList 
            type="TwoDayBundle"
            header="7th to 12th Grade"
            subhead="7:30PM to 9:00PM"
            data={trainingOption2Bundle}
          />
          {/* <PurchaseList 
            type="TwoDayBundle"
            header="1 Day Only Dec 19th"
            subhead="1 Class $30"
            data={thursdayTrainingOption}
          /> */}
          {/* <PurchaseList
            type="Tuesday"
            header="Tuesday Training"
            subhead="$30/ 1 Class"
            data={tuesdayTrainingOption}
          />
          <PurchaseList
            type="Wednesday"
            header="Wednesday Training"
            subhead="$30/ 1 Class"
            data={wednesdayTrainingOption}
          />
            <PurchaseList
            type="Thursday"
            header="Thursday Training"
            subhead="$30/ 1 Class"
            data={thursdayTrainingOption}
          /> */}

        </div>

        <div className='logistics'>
        <Location 
            link="https://www.d114.org/elementary-school"
            name="Lotus School Court 2"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={trainingDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}