import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.scss';

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import HomePage from './pages/HomePage/HomePage.js';
import AboutUs from './pages/AboutUs/AboutUs';
import Values from './pages/AboutUs/Values';
import Team from './pages/AboutUs/Team';
import ContactUs from './pages/ContactUs/ContactUs.js';
import NowHiring from './pages/NowHiring/NowHiring';
import Feedback from './pages/Feedback/Feedback.js';

import Enrichment from './pages/Enrichment/General/Enrichment';

import GrassLakeSpring from './pages/Enrichment/Grasslake/GrassLakeSpring';
import LotusSpringTwo from './pages/Enrichment/Lotus/LotusSpringTwo'
import Stanton from './pages/Enrichment/Stanton/Stanton'


import CampsAndClinics from './pages/CampsAndClinics/CampsAndClinics';
import WeekCamps from './pages/CampsAndClinics/WeekCamps';
import Holiday from './pages/CampsAndClinics/Holiday';
import Peewee from './pages/CampsAndClinics/Peewee';

import PopInTraining from './pages/Training/PopInTraining';

import PlayTymeSoccer from './pages/PlayTyme/PlayTymeSoccer';
import PlayTymeFootball from './pages/PlayTyme/PlayTymeFootball';
import PlayTymeBasketball from './pages/PlayTyme/PlayTymeBasketball';
import PlayTymeBasketballKto2 from './pages/PlayTyme/PlayTymeBasketbalKto2';

import Training from './pages/Training/Training0';
import Training1 from './pages/Training/Training1';
import Training2 from './pages/Training/Training2';
import Training2Older from './pages/Training/Training2Older';
import Training3 from './pages/Training/Training3';
import Training3Older from './pages/Training/Training3Older';
import Training4 from './pages/Training/Training4';
import Training4Older from './pages/Training/Training4Older';
import Training5 from './pages/Training/Training5';
import Training5Older from './pages/Training/Training5Older';
import Training6 from './pages/Training/Training6';
import Training6Older from './pages/Training/Training6Older';


import FootBallTrainingThreetoSix0 from './pages/Training/FootballTrainingThreeToSix0';
import FootBallTrainingSeventoTwelve0 from './pages/Training/FootballTrainingSevenToTwelve0';

class App extends Component {
  
  render() {
    return (
      <BrowserRouter>
        <div className="background">
            <Header />
            <Route exact path="/" component={HomePage} />
            <Route path="/About" component={AboutUs} />
            <Route path="/Values" component={Values} />
            <Route path="/Team" component={Team} />
            <Route path="/Enrichment" component={Enrichment} />
            <Route path="/CampsAndClinics" component={CampsAndClinics} />
            <Route path="/PopInTraining" component={PopInTraining} />

            {/* <Route path="/PlayTymeSoccer" component={PlayTymeSoccer} />
            <Route path="/PlayTymeFootball" component={PlayTymeFootball} />
            <Route path="/PlayTymeBasketball" component={PlayTymeBasketball} />
            <Route path="/PlayTymeBasketballKto2" component={PlayTymeBasketballKto2} /> */}

            <Route path="/Training" component={Training} />
            <Route path="/Training1" component={Training1} />
            <Route path="/Training2" component={Training2} />
            <Route path="/Training27to12" component={Training2Older} />
            <Route path="/Training3" component={Training3} />
            <Route path="/Training37to12" component={Training3Older} />
            <Route path="/Training4" component={Training4} />
            <Route path="/Training47to12" component={Training4Older} />
            <Route path="/Training5" component={Training5} />
            <Route path="/Training57to12" component={Training5Older} />
            <Route path="/Training6" component={Training6} />
            <Route path="/Training67to12" component={Training6Older} />

            <Route path="/FootballTraining" component={FootBallTrainingThreetoSix0} />

            <Route path="/606atGLS" component={GrassLakeSpring}/>
            <Route path="/606atLotus" component={LotusSpringTwo}/>
            <Route path="/606atStanton" component={Stanton}/>

            <Route path="/Peewee" component={Peewee} />
            <Route path="/Holiday" component={Holiday} />
            <Route path="/WeekCamps" component={WeekCamps} />
            <Route path="/Employment" component={NowHiring} />
            <Route path="/ContactUs" component={ContactUs} />
            <Route path="/Feedback" component={Feedback} />
            
        </div>
        <Footer />

      </BrowserRouter>
    );
  }
}

export default App;