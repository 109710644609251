const navbarLinks = [
  {
    category: "Home",
    link: "/",
    links: [],
  },
  {
    category: "About Us",
    link: "#",
    links: [
      {
        name: "Our Story",
        url: "/About",
      },
      {
        name: "Our Values",
        url: "/Values",
      },
    ],
  },
  // {
  //   category: "Pop In Training",
  //   link: "#",
  //   links: [
  //     {
  //       name: "Basketball Jan 7th to 16th",
  //       url: "/PopInTraining",
  //     },
  //   ],
  // },
  {
    category: "Basketball",
    link: "#",
    links: [
      // {
      //   name: "Nov 6 to Nov 27",
      //   url: "/Training1",
      // },
      // {
      //   name: "3rd to 6th Grade Dec 3 to Jan 16",
      //   url: "/Training2",
      // },
      // {
      //   name: "7th to 12th Grade Dec 3 to Jan 16",
      //   url: "/Training27to12",
      // },
      {
        name: "Jan 22 to Feb 20",
        url: "/Training3",
      },
      // {
      //   name: "3rd to 6th Grade Feb 25 to Apr 3",
      //   url: "/Training4",
      // },
      // {
      //   name: "7th to 12th Grade Feb 25 to Apr 3",
      //   url: "/Training47to12",
      // },
      // {
      //   name: "3rd to 6th Grade April 8 to April 29",
      //   url: "/Training5",
      // },
      // {
      //   name: "7th to 12th Grade April 8 to April 29",
      //   url: "/Training57to12",
      // },
      // {
      //   name: "3rd to 6th Grade May 6 to May 28",
      //   url: "/Training6",
      // },
      // {
      //   name: "7th to 12th Grade May 6 to May 28",
      //   url: "/Training67to12",
      // },
    ],
  },
  // {
  //   category: "Football",
  //   link: "#",
  //   links: [
  //  {
  //       name: "Jan 21 to Feb 20",
  //       url: "/FootballTraining",
  //     },
  //   ],
  // },
  {
    category: "Camps",
    link: "#",
    links: [
      {
        name: "1 Day Holiday Camps",
        url: "/Holiday",
      },
      {
        name: "Week Long School Break Camps",
        url: "/WeekCamps",
      },
      {
        name: "Peewee Clinics - Ages 3-5",
        url: "/Peewee",
      },
    ],
  },
  {
    category: "Enrichment",
    link: "#",
    links: [
      {
        name: "What we offer",
        url: "/Enrichment", 
      },
    ],
  },
  // {
  //   category: "Play Tyme Gym",
  //   link: "#",
  //   links: [
  //     {
  //       name: "Soocer Ages 4 to 6",
  //       url: "/PlayTymeSoccer", 
  //     },
  //     {
  //       name: "Football Ages 4 to 6",
  //       url: "/PlayTymeFootball", 
  //     },
  //     {
  //       name: "Basketball Ages 4 to 6",
  //       url: "/PlayTymeBasketball", 
  //     },
  //     {
  //       name: "Basketball Grades K to 2nd",
  //       url: "/PlayTymeBasketballKto2", 
  //     },
  //   ],
  // },
  {
    category: "Afterschool Clubs",
    link: "#",
    links: [
      {
        name: "Grass Lake School",
        url: "/606atGLS", 
      },
      {
        name: "Lotus School",
        url: "/606atLotus", 
      },
      {
        name: "Stanton School",
        url: "/606atStanton", 
      },
    ],
  },
  {
    category: "Now Hiring",
    link: '/Employment',
    links: [],
  },
  {
    category: "Contact Us",
    link: '/ContactUs',
    links: [],
  }
];


export { navbarLinks };