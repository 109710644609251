import React, { Component } from 'react';
import Video from '../../HomePage/Video'
import {enrichText, grInfo} from './EnrichData'
import '../Enrichment.scss';
import 'animate.css';

class Enrichment extends Component {
  render() {
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp">
          <div id="enrichment" className="info d-flex flex-column align-items-center justify-content-center" data-testid="Enrichment">
            <h2>Enrichment Services</h2>
            <p className="text-justify">
              {enrichText}
            </p>
          </div>
          <div className="enrichment__container">
            <div className='enrichment-video__container'>
              <Video url="https://youtu.be/c02pwIT2iLQ"/>
            </div>
            <div className="enrichment-gl-data__container">
              {grInfo && grInfo.length > 0 && grInfo.map((gradeBand) =>
                <div key={gradeBand.name} className="classes-col col-md-6">
                  <h5>{gradeBand.name}</h5>
                  <ul className="classes list-group list-group-flush">
                    {gradeBand.description.map((activity) => 
                    <li key={activity} className="list-group-item bg-transparent text-white">{activity}</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
      </section>
    );
  }
}

export default Enrichment;