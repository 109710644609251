import React from 'react'

export default function Tabs() {
   return (
      <div className="col-sml-4 col-md-4 col-lg-5 pb-4 flex-shrink-1">
         <div className="list-group" id="list-tab" role="tablist">
            <a className="camps-list list-group-item list-group-item-action" id="list-home-holidaycamps" data-toggle="list" href="#list-holidaycamps" role="tab" aria-controls="holiday camps">
               1 Day Holiday Camps
            </a>
            <a className="camps-list list-group-item list-group-item-action" id="list-tab-camps" data-toggle="list" href="#list-camps" role="tab" aria-controls="week-long camps">
               Week-Long School Break Camps
            </a>
            <a className="camps-list list-group-item list-group-item-action" id="list-tab-peewee" data-toggle="list" href="#list-peewee" role="tab" aria-controls="peewee clinics">
               Pee Wee Clinics - Ages 3-5
            </a>
            {/* <a className="camps-list list-group-item list-group-item-action active classes" id="list-tab-rla" data-toggle="list" href="#list-rla" role="tab" aria-controls="Round Lake Area spring trainings">
               2022 Spring Training in Round Lake - <span>Ages 10-14</span>
            </a> */}
            {/* <a className="camps-list list-group-item list-group-item-action active classes" id="list-tab-summer" data-toggle="list" href="#list-tab-summer" role="tab" aria-controls="Round Lake Area summer trainings">
               2022 Summer Camp in Round Lake - <span>Grades 1-8</span>
            </a> */}
         </div>
      </div>
   )
}