import React, { Component } from 'react';
import { Staff } from './Staff';
import './About.scss'

class Team extends Component {
  render() {
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center flex-md-row justify-content-md-start align-items-md-start flex-lg-row justify-content-lg-start align-items-lg-start">
        <Staff />
      </section>
    );
  }
}

export default Team;