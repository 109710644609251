import isso_photo from '../../static/staff_bio/ismaila.png'
import greg_photo from '../../static/staff_bio/greg.png'
import will_photo from '../../static/staff_bio/will.png'
import b_photo from '../../static/staff_bio/bouna.png'
import jaronn_photo from '../../static/staff_bio/jaronn.png'



export const staffData = [
  {
    id: 1,
    name: "Jaronn Watkins",
    bio: 'Coach Jaronn Watkins grew up in Evanston, Illinois, played collegiate basketball for North Park University and Oakton Community College, and is a proud "girl dad". Jaronn has 10+ years of experience in skills training. He has been a dedicated coach and mentor helping young athletes reach their true potential. Jaronn has worked with WNBA superstar Jewell Loyd and professional basketball player Jarryd Loyd. Jaronn prides himself on making basketball fun while teaching  the fundamentals of the game as well as skills players will carry with them throughout their lives.',
    // img: "jaronn",
    img: jaronn_photo,
    email: "euggyg23@gmail.com",
    email_subject: "Private Training with Coach Jaronn/606 Academy",
    email_body: "Hello, I am interested in setting up private training with Coach Jaronn through the 606 Academy website.",
    btn_text: "Contact Coach Jaronn about private training"
  },
  {
    id: 2,
    name: "Greg Wegrzynowicz",
    bio: "Coach Greg Wegrzynowicz is a USA Basketball licensed coach, National Academy of Sports Medicine Certified Personal Trainer, and Marine Corps Veteran that has over 12 years of experience coaching basketball in the Chicagoland area with clubs and schools including All In Athletics, Full Package, Illinois Central Elite, Queen of All Saints, and Baker Demonstration School.",
    img: greg_photo,
    email: "greg@606academy.com",
    email_subject: "Private Training with Coach Greg/606 Academy",
    email_body: "Hello, I am interested in setting up private training with Coach Greg through the 606 Academy website.",
    btn_text: "Contact Coach Greg about private training"
  },
  {
    id: 4,
    name: "Will Stenson",
    bio: 'William Stenson III is the Head Coach/Administrator for Process Over Result Teaching and has expierence coaching basketball for the Zion Park District. Coach Will focuses on technique and player development while getting the parents more involved in his programs. Teaching, communicating with parents, and watching his players grow on and off the court brings Will great joy. Coach Will always give his best effort because he believes coaching youth sports is a privilege. We are thankful to have Coach Will on the team! ',
    // img: "chris",
    img: will_photo,
    email: "Bgwill1019@aol.com",
    email_subject: "Private Training with Coach Chris/606 Academy",
    email_body: "Hello, I am interested in setting up private training with Coach Will through the 606 Academy website.",
    btn_text: "Contact Coach Will about private training"
  },
  {
    id: 2,
    name: "Ismaila Kane",
    bio: "Coach Ismaila Kane (a.k.a. Coach Isso) is a basketball trainer and player who played for Atlanta Metropolitan State College and the NBA G League teams Memphis Hustle and Iowa Wolves. Ismaila currently lives in the Chicagoland area, has 4 brothers, is married with a little one on the way, and was born in Senegal.",
    // img: "ismaila",
    img: isso_photo,
    email: "ladsame97@gmail.com",
    email_subject: "Private Training with Coach Isso/606 Academy",
    email_body: "Hello, I am interested in setting up private training with Coach Ismaila through the 606 Academy website.",
    btn_text: "Contact Coach Isso about private training"
  },
  {
    id: 5,
    name: "Bouna Kebe",
    bio: "Coach Bouna Kebe (a.k.a. Coach B) played for Lincoln Prep Academy and CORE4 Atlanta. He is a Division 1 prospect with pro-level experience. Bouna was born in Senegal and lives in the Chicagoland area. When he's not dunking on the court, he loves to share his love of basketball by training a new generation of players.",
    // img: "bouna",
    img: b_photo,
    email: "kebebouna99@gmail.com",
    email_subject: "Private Training with Coach B/606 Academy",
    email_body: "Hello, I am interested in setting up private training with Coach B through the 606 Academy website.",
    btn_text: "Contact Coach B about private training"
  },
  // {
  //   id: 7,
  //   name: "Chris Ohlsen",
  //   bio: 'Coach Chris Ohlsen is a retired U.S. Marine Corps Reserve Veteran, father of 3, and holds degrees in K-12 Education and Industrial Technology Manufacturing. Coach Chris is an overall athlete and has experience coaching youth basketball and softball.',
  //   // img: "chris",
  //   img: chris_photo,
  //   email: "chris.ohlsen9@gmail.com",
  //   email_subject: "Private Training with Coach Chris/606 Academy",
  //   email_body: "Hello, I am interested in setting up private training with Coach Chris through the 606 Academy website.",
  //   btn_text: "Contact Coach Chris about private training"
  // },
]