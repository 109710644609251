import scrimmage from '../../static/scrimmage.jpg'
import 'animate.css';

export default function Peewee() {
   return (
    <section className="section d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp">
    <div className="info camps__container">
      <div className="camp__col">      
        <h4>Peewee Clinics - Ages 3-5</h4>
        <div>
        <p>
          Our peewee clinics are 45 minute sessions designed to teach your kids the fundamentals of the game and to help them develop a love for basketball.
          </p>
          <p>
          Clinics involve a variety of age-appropriate dribbling and passing games designed to make learning fun and to keep  kids engaged. The primary focus is  ball handling and passing, but the little ones will work on all aspects of the game throughout the clinic, including shooting and defense. 
          </p>
          <p>
          <a className="camps-contact links" href="/ContactUs">Click here to request more information about how to set up a peewee clinic!</a>
          </p>
        </div>
      </div>
      <div className="camp__col">
        <img src={scrimmage} alt="606 Academy kids work and play hard!" className='camp__img' />
      </div>
    </div>
</section>
   )
}