import drills from '../../static/drills.jpg'
import 'animate.css';

export default function WeekCamps() {
   return (
    <section className="section d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp">
      <div className="info camps__container">
        <div className="camp__col">      
          <h4>Week-Long School Break Camps</h4>
          <div>
            <p>
            Designed for longer school breaks, week long, full day basketball camps provide an opportunity for kids to work on their game, to socialize with peers in an inclusive atmosphere, and to be in a safe place when school is not in session.
            </p>
            <p>
            Camp is structured to include a snack break and lunch period where kids can watch a movie related to sports.
            </p>
            <p>
            <a className="camps-contact links" href="/ContactUs">Click here to request more information about how to set up a week-long camp!</a>
            </p>
          </div>
        </div>
        <div className="camp__col">
          <img src={drills} alt="606 Academy kids work and play hard!" className='camp__img' />
        </div>
      </div>
  </section>
   )
}