import { useState } from 'react';
import Video from '../HomePage/Video'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { staffData } from "./StaffData";
import 'animate.css';

export function Staff() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="team-container animate__animated animate__fadeInUp">
      <h4>Our Team</h4>
      <button type="button" className="modal-btn" onClick={handleShow}>
        Launch Meet the Team Video
      </button>

      <Modal show={show} onHide={handleClose} className="modal">
        <Modal.Header closeButton variant="white">
          <Modal.Title>Meet the 606 Academy Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Video url="https://youtu.be/vThR6CpFsXw"/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="about-staff" className="about-staff">
        {staffData &&
          staffData.length > 0 &&
          staffData.map((member) => (
            <div key={member.id} className="staff__card">
              <img
                className="img-fluid mb-4 pl-0"
                src={`${member.img}`}
                alt={member.name}
              />
              <h5>{member.name}</h5>
              <p>{member.bio}</p>
              <a
                href={`mailto:${member.email}?Cc=greg@606academy.com&subject=${member.email_subject}&body=${member.email_body} `}
              >
                <button className="team-btn">{member.btn_text}</button>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}
