import PurchaseListLotus from '../../../components/PurchaseListLotus';
import Location from '../../../components/Location.jsx'
import LogisiticsDetails from '../../../components/LogisiticsDetails';
import 'animate.css';
import StantonLogo from '../../../static/Stanton/StantonLogo.png';
import {StantonDrills, StantonInfo, StantonOption1IndividualSessions, StantonOption2IndividualSessions, StantonOption1Bundle, StantonOption2Bundle} from './StantonData'
import './stanton.scss'
import '../../../App.scss'

export default function Stanton() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
    <div className="info camps__container animate__animated animate__fadeInRight">
      <h4>Wednesday Enrichment for Stanton Students at Lotus School - Grades 5 to 8 </h4>
      <h4 className='disclaimer'>This afterschool program is for Stanton School Students held at Lotus</h4>
      <h4 className='disclaimer'>No CLass on 2/26, 3/5 and 3/26.</h4>
      {/* <h4 className='disclaimer'>Registration Extended Until 9/3 Midnight!</h4> */}
      <div className='lotus__container'>
        <div className="purchase">
          <PurchaseListLotus 
            type="bundle-option2"
            header="Wednesday After School"
            subhead="All Sports!"
            data={StantonOption1Bundle}
          />
        </div>

        <div className='logistics'>
        <img
              className="img-fluid mb-4 pl-0"
              src={StantonLogo}
              alt="Stanton Logo"
            />
       <Location 
            link="https://maps.app.goo.gl/Xj8rJGuBx6k5BkDk8"
            name="Lotus School"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={StantonInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={StantonDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}