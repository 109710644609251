import React, { Component } from 'react'
import IntroText from './IntroText'
import Tabs from './Tabs'
import Holidays from './Holiday'
import WeekCamps from './WeekCamps'
import Peewee from './Peewee'
import '../../App.scss'

class CampsAndClinics extends Component {
  render() {
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center">
        <IntroText />
        <div className="d-flex flex-row flex-wrap align-items-start justify-content-center camps p-3">
          <Tabs />
          <div className="col-sml-6 col-md-7 col-lg-7">
            <div className="tab-content" id="nav-tabContent">
              <Holidays />
              <WeekCamps />
              <Peewee />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CampsAndClinics;