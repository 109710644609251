const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-dec-3rd-to-jan-16-tues-wed-thurs/",
    name: "3 Day Bundle T/W/Th",
    date: "",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-dec-3rd-to-jan-16-tues-wed-thurs/",
    name: "2 Day Bundle T/W/Th",
    date: "",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-dec-3rd-to-jan-16-tues-wed-thurs/",
    name: "December 3rd",
    date: "Janurary 14th",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-dec-3rd-to-jan-16-tues-wed-thurs/",
    name: "December 4th",
    date: "January 15th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-dec-3rd-to-jan-16-tues-wed-thurs/",
    name: "December 5th",
    date: "January 16th",
  },
]


const trainingInfo = [
  "No training on Dec 10, 11, 18, 24, 25, 26, 31, Jan 1, and 2",
  "Registration closes one week prior to start date",
  "Individual day registration starts at $45 and available upon request",
  "Bring a basketball and a water bottle.",
  "3rd Grade to 11th Grade",
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}