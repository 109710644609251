import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function ContactIcons({ name }) {

  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      <a className="contact-btn btn-lg btn-primary" href={`mailto:${name.email}?Subject=Hello`} target="_blank" rel="noopener noreferrer" role="button">
        <FontAwesomeIcon 
          icon={faEnvelope} 
          className="contact-icon"
        />
      </a>  
    </div>
  )
}