import React from 'react'
import ContactIcons from './ContactIcons'


export default function ContactText() {

  const contactGreg =
    {
      "id": 2,
      "title": "Coach",
      "name": "Greg Wegrzynowicz",
      "email": "Greg@606Academy.com",
      "phone": "3126628256",
      "formattedPhone": "312-662-8256"
    }

   return (
    <div className='contact__container'>
      <div className="am">
        <h3>Contact 606 Academy</h3>
        <div className="text-sm-left text-md-justify text-lg-justify">
          <p>{contactGreg.title} {contactGreg.name}</p>
          <ContactIcons name={ contactGreg }/>           
          <p>
            <a className="contact-text-links" href={`mailto:${contactGreg.email}?Subject=Hello`}>
              {contactGreg.email}
            </a>
          </p>
          <p>
            <a className="contact-text-links" href={`tel:${contactGreg.phone}`}>
              {contactGreg.formattedPhone}
            </a>
          </p>
      </div>
      </div>
      {/* <div className="pm">
        <h3>5pm-9pm Contact</h3>
        <div className="text-sm-left text-md-justify text-lg-justify">
          <p>{contactLeyna.title} {contactLeyna.name}</p>
          <ContactIcons name={ contactLeyna }/>           
          <p>
            <a className="contact-text-links" href={`mailto:${contactLeyna.email}?Cc=${contactGreg.email}&Subject=Hello`}>
              {contactLeyna.email}
            </a>
          </p>
          <p>
            <a className="contact-text-links" href={`tel:${contactLeyna.phone}`}>
              {contactLeyna.formattedPhone}
            </a>
          </p>
        </div>
      </div> */}
    </div>
   )
}