import React from 'react'

function Location({link, name, street, city} ) {
  return (
    <div className="logistics--detail">
      <h5>Location</h5>
      <a className="links" href={link} target="_blank" rel="noreferrer">
        {name}
      </a>
      <address>
        {street}
        <br></br>
        {city}
      </address>
    </div>
  )
}

export default Location