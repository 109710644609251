import React from 'react'

function PurchaseList({type, header, subhead, data}) {
  return (
    <div className="purchase-list" id={type}>
    <h5 className='text-center'>{header}</h5>
    <h6 className='text-center'>{subhead}</h6>
    {data.map((item) => (
      <div key={item.link} className="purchase-item">
        <a href={item.link} target="_blank" rel="noreferrer">
          <button className={`purchase-btn ${type}`}>
            <h6 className="purchase-btn--text line1">{item.name} / {item.date}</h6>
            {item.time && item.price &&
            <h6 className="purchase-btn--text">{item.time} / {item.price}</h6>}
          </button> 
        </a>
      </div>
    ))}
  </div>
)
}

export default PurchaseList