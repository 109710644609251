import React from 'react'

function PurchaseListGrasslake({type, header, subhead, data}) {
  return (
    <div className="purchase-list" id={type}>
    <h5 id={`${type}`}>{header}</h5>
    <h6 className='text-center'>{subhead}</h6>
    {data.map((item) => (
      <div key={item.link} className="purchase-item">
        <a href={item.link} target="_blank" rel="noreferrer">
          <button className={`${type}`}>
            <h6 className="btn-text">{item.name} / {item.date}</h6>
          </button> 
        </a>
      </div>
    ))}
  </div>
)
}

export default PurchaseListGrasslake