const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-feb-26-to-april-3rd-tues-wed-thurs/",
    name: "3 Day Bundle T/W/TH",
    date: "",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-feb-26-to-april-3rd-tues-wed-thurs/",
    name: "2 Day Bundle T/W/TH",
    date: "",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-feb-26-to-april-3rd-tues-wed-thurs/",
    name: "March 4th",
    date: "April 1st",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-feb-26-to-april-3rd-tues-wed-thurs/",
    name: "February 26th",
    date: "April 2nd",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-feb-26-to-april-3rd-tues-wed-thurs/",
    name: "February 27th",
    date: "April 3rd",
  },
]


const trainingInfo = [
  "No Training on March, 12, 19, 25, 26, and 27",
  "Registration closes one week prior to start date",
  "Individual day registration starts at $45 and available upon request",
  "Bring a basketball and a water bottle.",
  "3rd Grade to 11th Grade",
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}