import React from 'react'

export default function IntroText() {
   return (
      <div id="enrichment" className="info d-flex flex-column align-items-center justify-content-center">
         <h2>Camps & Clinics</h2>
         <p className="text-sm-left text-md-justify text-lg-justify">
            606 Academy offers day camp/clinic programming for K-8 students. Click to find out more about each offering.
         </p>
    </div>
   )
}