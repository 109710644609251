import {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption, trainingOption1Bundle, trainingOption2Bundle} from './Training6Data'
import PurchaseList from '../../components/PurchaseList';
import PurchaseListNavTraining from '../../components/PurchaseListNavTraining';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function Training6() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training Grades 3 to 6 May 6 to May 28</h4>
        <h4 className='disclaimer'>Training Time is 6:00PM to 7:30PM</h4>
        <h4 className='disclaimer'>No Training May 15th and 29th</h4>
        <div className='camp__container'>
          <div className="purchase">
          <PurchaseListNavTraining url="Training6" />
          <PurchaseList 
            type="ThreeDayBundle"
            header="3 Day Bundle Tue-Wed-Thurs May 6 to May 28"
            subhead="10 Classes for 1 payment of $181 //  $20 in savings!"
            data={trainingOption1Bundle}
          />
          <PurchaseList 
            type="TwoDayBundle"
            header="2 Day Bundle Tue-Wed-Thurs May 6 to May 29"
            subhead="6 to 8 Classes $111 to $129.50 // $5 to $10 in savings!"
            data={trainingOption2Bundle}
          />
          <PurchaseList
            type="Tuesday"
            header="Tuesday Training from 6:00-7:30pm"
            subhead="$80/ 4 Classes"
            data={tuesdayTrainingOption}
          />
          <PurchaseList
            type="Wednesday"
            header="Wednesday Training from 6:00-7:30pm"
            subhead="$80/ 4 Classes"
            data={wednesdayTrainingOption}
          />
            <PurchaseList
            type="Thursday"
            header="Thursday Training from 6:00-7:30pm"
            subhead="$40/ 2 Classes"
            data={thursdayTrainingOption}
          />

        </div>

        <div className='logistics'>
        <Location 
            link="https://www.d114.org/elementary-school"
            name="Lotus School"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={trainingDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}