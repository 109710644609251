import React from 'react'
import { NavHashLink } from 'react-router-hash-link';

function PurchaseListNavTraining({url}) {
  return (
    <nav className='interpage-nav'>
      <NavHashLink
        to={`/${url}#ThreeDayBundle`}
        className="interpage-nav-link"
        activeClassName="selected"
        activeStyle={{ color: '#00b0f0' }}
      >
        3 Day Bundle
      </NavHashLink>
      <NavHashLink
        to={`/${url}#TwoDayBundle`}
        className="interpage-nav-link"
        activeClassName="selected"
        activeStyle={{ color: '#00b0f0' }}
      >
        2 Day Bundle
      </NavHashLink>
      <NavHashLink
        to={`/${url}#Tuesday`}
        className="interpage-nav-link"
        activeClassName="selected"
        activeStyle={{ color: '#00b0f0' }}
      >
        Tues
      </NavHashLink>
      <NavHashLink
        to={`/${url}#Wednesday`}
        className="interpage-nav-link"
        activeClassName="selected"
        activeStyle={{ color: '#00b0f0' }}
      >
        Wed
      </NavHashLink>
      <NavHashLink
        to={`/${url}#Thursday`}
        className="interpage-nav-link"
        activeClassName="selected"
        activeStyle={{ color: '#00b0f0' }}
      >
        Thurs
      </NavHashLink>
    </nav>
  )
}

export default PurchaseListNavTraining