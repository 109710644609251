import {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption, trainingOption1Bundle, trainingOption2Bundle} from './Training5Data'
import PurchaseList from '../../components/PurchaseList';
import PurchaseListNavTraining from '../../components/PurchaseListNavTraining';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function Training5older() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training Grades 7 to 12 April 8 to April 29</h4>
        <h4 className='disclaimer'>Training Time is 7:30PM to 9:00PM</h4>
          <h4 className='disclaimer'>No Training April 23rd, 30th</h4>
        <div className='camp__container'>
          <div className="purchase">
          <PurchaseListNavTraining url="Training5" />
          <PurchaseList 
            type="ThreeDayBundle"
            header="3 Day Bundle Tue-Wed-Thurs Apr to May"
            subhead="9 Classes for 1 payment of $157.50 //  $30 in savings!"
            data={trainingOption1Bundle}
          />
          <PurchaseList 
            type="TwoDayBundle"
            header="2 Day Bundle Tue-Wed-Thurs Apr to May"
            subhead="5 to 7 Classes $92.50 to $129.50 // $10 in savings!"
            data={trainingOption2Bundle}
          />
          <PurchaseList
            type="Tuesday"
            header="Tuesday Training from 7:30pm to 9pm"
            subhead="$80/ 4 Classes"
            data={tuesdayTrainingOption}
          />
          <PurchaseList
            type="Wednesday"
            header="Wednesday Training from 7:30pm to 9pm"
            subhead="$40/ 2 Classes"
            data={wednesdayTrainingOption}
          />
            <PurchaseList
            type="Thursday"
            header="Thursday Training from 7:30pm to 9pm"
            subhead="$60/ 3 Classes"
            data={thursdayTrainingOption}
          />

        </div>

        <div className='logistics'>
        <Location 
            link="https://www.d114.org/elementary-school"
            name="Lotus School"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={trainingDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}