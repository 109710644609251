import PurchaseListGrasslake from '../../../components/PurchaseListGrasslake';
import Location from '../../../components/Location.jsx'
import LogisiticsDetails from '../../../components/LogisiticsDetails';
import 'animate.css';
import GrassLakeLogo from '../../../static/GrassLake/GrassLakeLogo.png';
import {grasslakeFall2023Drills, grasslakeFall2023AInfo, grasslakeFall2023option1IndividualSessions, grasslakeFall2023option2IndividualSessions, grasslakeFall2023option1Bundle, grasslakeFall2023option2Bundle, grasslakeFallActivites, grasslakeIndividualSessions} from './GrassLakeSpringData'
import './grasslake.scss'
import '../../../App.scss'
import Carousel from './Carousel'

export default function GrassLakeSpring() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
      <h4>All Sports Clubs Grades K-8</h4>
        <h4 className='disclaimer'>No Class on 2/14; 3/21; 3/28; 4/18;</h4>
      <div className="info">
        </div>
        <div className='grasslake__container'>
          <div className="purchase">
            {/* <PurchaseListGrasslake 
              type="bundle-option1"
              header="Fridays After School Club"
              subhead="After School Club!"
              data={grasslakeFall2023option1Bundle}
            /> */}
            <PurchaseListGrasslake 
              type="bundle-option2"
              header="Fridays All Sports Club"
              subhead="All Sports Club!"
              data={grasslakeFall2023option2Bundle}
            />
            {/* <PurchaseListGrasslake 
              type="individual-option1"
              header="6 Classes from 1:15-4:00pm"
              subhead="$115/ 6 Sessions"
              data={grasslakeFall2023option1IndividualSessions}
            />
            <PurchaseListGrasslake 
              type="individual-option2"
              header="6 Classes from 1:15-2:45pm"
              subhead="$72/ 6 Sessions"
              data={grasslakeFall2023option2IndividualSessions}
            />
            <PurchaseListGrasslake 
              type="individual-option1"
              header="1 Class from $12 to $20"
              subhead="One Session Only"
              data={grasslakeIndividualSessions}
            /> */}
          </div>

          <div className='logistics'>
          <img
                className="img-fluid mb-4 pl-0"
                src={GrassLakeLogo}
                alt="Antioch Logo"
              />
            <Location 
              link="https://www.gls36.org/"
              name="Grass Lake School"
              street="26177 W. Grass Lake Rd,"
              city="Antioch, IL 60002"
            />
            <LogisiticsDetails 
              header="Important Notes"
              data={grasslakeFall2023AInfo}
            />
            <LogisiticsDetails 
              header="Sports and Games"
              data={grasslakeFall2023Drills}        
            />
              <LogisiticsDetails 
              header="After Care Themes"
              data={grasslakeFallActivites}        
            />
          </div>

        </div>
        {/* close camp container div */}
      </div>
      {/* close info div */}
    </section>
  )
}