const trainingOption1Bundle = [
  {
    link: "https://sixzerosixacademy.playbookapi.com/programs/more_info/class_package/35125/",
    name: "Grades 3 to 6",
    date: "6:00PM to 7:30PM",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://sixzerosixacademy.playbookapi.com/programs/more_info/class_package/35140/",
    name: "Grades 7 to 12",
    date: "7:30PM to 9:00PM",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "December 3rd",
    date: "Janurary 14th",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "December 4th",
    date: "January 15th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "December 5th",
    date: "January 16th",
  },
]


const trainingInfo = [
  "Bring a footbal and a water bottle.",
]

const trainingDrills = [
  "Quarterback: Footwork, Proper Throwing Technique, Speed and Agility, Ball Placement",
  "Wide Receiver: Route Running, Ball Protection, Footwork, Speed and Agility, Catching the Ball, Ball Tracking",
  "Running Back: Footwork, Ball Protection, Catching the Ball, Speed and Agility, Explosive Backfield Drills"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}