import React, { Component } from 'react'
import ContactText from './NowHiringText'
import './NowHiring.scss'
import 'animate.css';

class NowHiring extends Component {
  render() {
    
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp">
        <div id="contact" className="info d-flex flex-column align-items-center justify-content-center">
          <ContactText />
        </div>
      </section>
      );
    }
  
  }
export default NowHiring;