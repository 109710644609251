const lotusFall2023option1Bundle = [
  {
    link: "https://sixzerosixacademy.playbookapi.com/programs/more_info/class_package/35113/",
    name: "Click to Learn More",
    date: "Lotus Students Only",
  },
]

const lotusFall2023option2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/18-enrichment-session-1245-to-1/",
    name: "18 Enrichment Sessions",
    date: "1:15-2:45 PM",
  },
]

const lotusFall2023option1IndividualSessions = [
  // {
  //   link: "https://playyon.com/606-academy/programs/oct-16th-to-nov-20th-5-enrichm/",
  //   name: "**5 Sessions October 16th",
  //   date: "November 20th $96**",
  // },
  {
    link: "https://playyon.com/606-academy/programs/dec-4th-to-feb-5th-6-enrichment-sessions/",
    name: "December 4th",
    date: "February 5th",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-12th-to-march-19th-6-enrichment-sessions/",
    name: "February 12th",
    date: "March 19th",
  },
  {
    link: "https://playyon.com/606-academy/programs/april-2nd-to-may-21st-6-enrichment-sessions/",
    name: "April 2nd",
    date: "May 21st",
  },
]

const lotusFall2023option2IndividualSessions = [
  // {
  //   link: "https://playyon.com/606-academy/programs/oct-16th-to-nov-20th-6-enrichment-sessions-1245-2/",
  //   name: "** 5 Sessions October 16th",
  //   date: "November 20th $60 **",
  // },
  {
    link: "https://playyon.com/606-academy/programs/dec-4th-to-feb-5th-6-enrichment-sessions/",
    name: "December 4th",
    date: "February 5th",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-12th-to-march-19th-6-enrichment-sessions/",
    name: "February 12th",
    date: "March 19th",
  },
  {
    link: "https://playyon.com/606-academy/programs/april-2nd-to-may-21st-6-enrichment-sessions/",
    name: "April 2nd",
    date: "May 21st",
  },
]


const lotusFall2023AInfo = [
  "Registration closes one week prior to start date",
  "3/26 4/23, and 4/30;",
  "Registration after deadline subject to late registration fees.",
  "ONLY Lotus School students are eligible to sign up for this enrichment program",
  "Individual day registrations are available upon request.",
  "Individual day registration starts at $35 from 1:15 to 2:45PM; $50 from 1:15 to 4:00PM",
  "Held on early release Wednesdays",
  "Bring a basketball and a water bottle.",
  "K to 4th Grade welcome",
]

const lotusFall2023Drills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {lotusFall2023Drills, lotusFall2023AInfo, lotusFall2023option1IndividualSessions, lotusFall2023option2IndividualSessions, lotusFall2023option1Bundle, lotusFall2023option2Bundle}