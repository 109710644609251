import React, { Component } from 'react'
import PartnerIcons from './PartnerIcons'
import './PartnerFooter.scss'

class FooterPartner extends Component {
  render() {
    return (
      <section className="d-flex flex-column align-items-center justify-content-center">
        <h6 className='partners--header'>Partners</h6>
        <div className="bg-transparent m-0 d-flex flex-column justify-content-center align-items-center text-uppercase font-weight-bold">
          <PartnerIcons />
        </div>
      </section>
    );
  }
}

export default FooterPartner;