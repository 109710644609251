import jason from '../../static/drill-jason.jpg'
import 'animate.css';

export default function Holiday() {
   return (
    <section className="section d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp">
    <div className="info camps__container">
      <div className="camp__col">      
        <h4>1 Day Holiday Camps</h4>
        <div>
          <p>
            For days when students have the day off from school, 606 Basketball Academy provides one day camps. 606 camps keep kids engaged in physical activities in the context of a structured environment. Instead of staying at home, kids can have fun, make friends, and progress in basketball skills in the gym.
          </p>
          <p>
            <a className="camps-contact links" href="/ContactUs">Click here to request more information about how to set up a one day camp!</a>
          </p>
        </div>
      </div>
      <div className="camp__col">
        <img src={jason} alt="606 Academy kids work and play hard!" className='camp__img' />
      </div>
    </div>
</section>
   )
}