import React, { Component } from 'react'
import SocialIcons from './SocialIcons'
import Cp from './Cp'
import './Footer.scss'

class Footer extends Component {
  render() {
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center">
        <footer className="bg-transparent m-3 d-flex flex-column justify-content-center align-items-center text-uppercase font-weight-bold">
          <SocialIcons />    
          <Cp />
        </footer>
      </section>
    );
  }
}

export default Footer;


