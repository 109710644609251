const enrichText = [
   "606 Basketball Academy runs after school basketball classes for all students. Our classes have a maximum 15-1 student-coach ratio. We run sessions for children ages kindergarten-8th grade."
]

const grInfo = [
   {
      "name": "K-2nd Grade",
      "description": [
         "Lots of games",
         "Foster a love of playing basketball"
      ]
   },
   {
      "name": "3th-4th Grade",
      "description": [
         "Games",
         "Skills",
         "Fundamental Feedback Development"
      ]
   },
   {
      "name": "5th-6th Grade",
      "description": [
         "Skills",
         "Games",
         "Leadership Development"
      ]
   },
   {
      "name": "7th-8th Grade",
      "description": [
         "Small-sided games",
         "Skill Development",
         "Scrimmages",
         "Elite-level Leadership Development" 
      ]
   }
]

export {enrichText, grInfo}



