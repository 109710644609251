import React, { Component } from 'react';
import { Story } from './Story';
import './About.scss'

class AboutUs extends Component {
  render() {
    return (
      // <section className="section d-flex flex-column align-items-center justify-content-center flex-md-row justify-content-md-start align-items-md-start flex-lg-row justify-content-lg-start align-items-lg-start">
        <Story />
      // </section>
    );
  }
}

export default AboutUs;