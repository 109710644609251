 import React from 'react';
import logo from "../static/by-logo-nomrg.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import '../App.scss'
import {navbarLinks } from '../data.js'
import 'animate.css';

function Header() {

  return (
    <nav className="navbar navbar-expand-lg">
      <a href="/">
        <img src={logo} alt="606 Academy Logo" className='animate__animated animate__pulse animate__repeat-1'/>
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse"  data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <FontAwesomeIcon 
          icon={faBars} 
          className="nav-toggler-icon navbar-menu-bars"
        />
      </button>
      <div className="navbar-collapse collapse" id="navbarContent">       
        <ul className="navbar-nav m-0 d-flex flex-column align-items-center justify-content-center flex-lg-row align-items-lg-center justify-content-lg-end">
          {navbarLinks.map((item) => (
            <li key={item.category} className={item.links && item.links.length > 0 ? "nav-item dropdown" : "nav-item"}>
              <a className={item.links && item.links.length > 0 ? "nav-link nav-links dropdown-toggle" : "nav-link nav-links"} href={item.link} role="button" id={item.links.length > 0 ? "navbarDropdown" : "navbarNoDrop"} data-toggle={item.links.length > 0 && "dropdown"} aria-haspopup={item.links.length > 0 && "true"} aria-expanded={item.links.length > 0 && "false"}>
                {item.category}
              </a>
              {item.links.length > 0 && <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {item.links.map((embeddedLink) => (
                  <a key={embeddedLink.name} className="dropdown-item" href={embeddedLink.url}>{embeddedLink.name}</a>
                ))}
              </div>}
            </li>
          ))}
        </ul>
      </div>
    </nav>  
    );
  }

export default Header;