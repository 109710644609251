import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default function SocialIcons() {
   return (
      <p className="socials d-flex flex-row justify-content-around">
      <a href="https://www.instagram.com/606academy" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.tiktok.com/@606bballacademy" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faTiktok} />
      </a>
      <a href="https://www.twitter.com/606academy" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a href="https://www.facebook.com/606coach" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href="https://www.youtube.com/channel/UCaEK6taWo8qia9fpNVCrudw/featured" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
      <a href="https://www.linkedin.com/company/606basketballacademy/" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </p>
   )
}
