import './HomePage.scss'

export default function Current() {
   return (
      <div className="d-flex flex-column justify-content-start align-items-center current-comp-container">
        <div className="current-btn__container">
          {/* <h4 className='current-head'>Now Available</h4> */}
          <a className="home-btn current-btn btn-lg current2-btn" href="/Training3" role="button">3-6 Grade B-Ball </a>
          <hr />
          <a className="home-btn current-btn btn-lg current2-btn" href="/Training3" role="button">7-12 Grade B-Ball </a>
          <hr />
        </div>
      </div>
    )
}