import React from 'react'

function LogisiticsDetails({header, data}) {
  return (
    <div className="logistics--detail">
    <h5>{header}</h5>
    <ul>
      {data.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  </div>
)
}

export default LogisiticsDetails