import React, { Component } from 'react';

class Feedback extends Component {
  render() {
    return (
      <div className="row camps">
      <div className="col-4 button-camps-border">
        <div className="list-group " id="list-tab" role="tablist">
          <a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home">Parent Feedback</a>
          <a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile">Child Feedback</a>
        </div>
      </div>
      <div className="col-8">
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list"><iframe title="feedback" className= "survey" src="https://docs.google.com/forms/d/e/1FAIpQLSerfwp6BU0LoqIy3aGlQ0t2xCqDNLkntG9VR6YKUJH6fLlRHA/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
          <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list"><iframe title="feedback" className="survey"src="https://docs.google.com/forms/d/e/1FAIpQLScBAKi8W-FRsqYfhQjV1FkkpfK5zptKjrwJpOMp62V-uEmF9g/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
        </div>
      </div>
    </div>
    );
  }
}

export default Feedback;